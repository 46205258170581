export default {
  namespaced: true,
  state: {
    regionGuid: "",
    userDispName: "",
    nodeGuid: "",
    nodeName: "",
    // 使用"测量码"登录的"开放测评"
    openLbGroupGuid: "",
    lbGroupGuid: "",
    /**
     * [{
     *  text: lb DisplayName,
     *  value: lb Guid,
     *  order: test order
     * }]
     */
    testLbList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    submittedCaseList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    savedCaseList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    cancelledCaseList: [],
    /**
     * [{
     *  lbId: lbId,
     *  lbDispName: lbDispName,
     *  caseGuid: submittedCaseGuid,
     * }]
     */
    showReportCaseList: [],
    currentLbGuid: "",
    /**
     * [ Answer Number ]
     */
    savedAnsList: [],
    personGuid: "",
    timeCost: 0,
    isFromExternal: false
  },

  getters: {
    regionGuid(state) {
      return state.regionGuid || sessionStorage.getItem("regionGuid") || "";
    },
    userDispName(state) {
      return state.userDispName || sessionStorage.getItem("userDispName") || "";
    },
    nodeGuid(state) {
      return state.nodeGuid || sessionStorage.getItem("nodeGuid") || "";
    },
    nodeName(state) {
      return state.nodeName || sessionStorage.getItem("nodeName") || "";
    },
    openLbGroupGuid(state) {
      return (
        state.openLbGroupGuid || sessionStorage.getItem("openLbGroupGuid") || ""
      );
    },
    lbGroupGuid(state) {
      return state.lbGroupGuid || sessionStorage.getItem("lbGroupGuid") || "";
    },
    testLbList(state) {
      if (!state.testLbList || !state.testLbList.length) {
        let storedTestLbList = sessionStorage.getItem("testLbList");
        return storedTestLbList ? JSON.parse(storedTestLbList) : [];
      }
      return state.testLbList;
    },
    submittedCaseList(state) {
      if (!state.submittedCaseList || !state.submittedCaseList.length) {
        let storedCaseList = sessionStorage.getItem("submittedCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.submittedCaseList;
    },
    savedCaseList(state) {
      if (!state.savedCaseList || !state.savedCaseList.length) {
        let storedCaseList = sessionStorage.getItem("savedCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.savedCaseList;
    },
    cancelledCaseList(state) {
      if (!state.cancelledCaseList || !state.cancelledCaseList.length) {
        let storedCaseList = sessionStorage.getItem("cancelledCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.cancelledCaseList;
    },
    showReportCaseList(state) {
      if (!state.showReportCaseList || !state.showReportCaseList.length) {
        let storedCaseList = sessionStorage.getItem("showReportCaseList");
        return storedCaseList ? JSON.parse(storedCaseList) : [];
      }
      return state.showReportCaseList;
    },
    currentLbGuid(state) {
      return (
        state.currentLbGuid || sessionStorage.getItem("currentLbGuid") || ""
      );
    },
    savedAnsList(state) {
      if (!state.savedAnsList || !state.savedAnsList.length) {
        let storedAnsList = sessionStorage.getItem("savedAnsList");
        return storedAnsList ? JSON.parse(storedAnsList) : [];
      }
      return state.savedAnsList;
    },
    personGuid(state) {
      return state.personGuid || sessionStorage.getItem("personGuid") || "";
    },
    timeCost(state) {
      return state.timeCost || sessionStorage.getItem("timeCost") || 0;
    },
    isFromExternal(state) {
      return (
        state.isFromExternal ||
        sessionStorage.getItem("isFromExternal") ||
        false
      );
    }
  },

  actions: {
    initScaState(
      { commit },
      {
        regionGuid,
        userDispName,
        nodeGuid,
        nodeName,
        openLbGroupGuid,
        personGuid
      }
    ) {
      commit("SET_REGION_GUID", regionGuid);
      commit("SET_USER_DISP_NAME", userDispName);
      commit("SET_NODE_GUID", nodeGuid);
      commit("SET_NODE_NAME", nodeName);
      commit("SET_OPEN_LB_GROUP_GUID", openLbGroupGuid);
      commit("SET_PERSON_GUID", personGuid);
    },
    clearScaState({ commit }) {
      commit("SET_REGION_GUID", "");
      commit("SET_USER_DISP_NAME", "");
      commit("SET_NODE_GUID", "");
      commit("SET_NODE_NAME", "");
      commit("SET_LB_GROUP_GUID", "");
      commit("SET_OPEN_LB_GROUP_GUID", "");
      commit("SET_TEST_LB_LIST", []);
      commit("SET_SUBMITTED_CASE_LIST", []);
      commit("SET_SAVED_CASE_LIST", []);
      commit("SET_CANCELLED_CASE_LIST", []);
      commit("SET_SHOW_REPORT_CASE_LIST", []);
      commit("SET_CURRENT_LB_GUID", "");
      commit("SET_SAVED_ANS_LIST", []);
      commit("SET_PERSON_GUID", "");
      commit("SET_IS_FROM_EXTERNAL", false);
      sessionStorage.clear();
    },
    clearStatedCaseList({ commit }) {
      commit("SET_SUBMITTED_CASE_LIST", []);
      commit("SET_SAVED_CASE_LIST", []);
      commit("SET_CANCELLED_CASE_LIST", []);
      commit("SET_LB_GROUP_GUID", "");
      commit("SET_IS_FROM_EXTERNAL", false);
    },
    clearShowReportCaseList({ commit }) {
      commit("SET_SHOW_REPORT_CASE_LIST", []);
    },
    setPersonGuid({ commit }, personGuid) {
      commit("SET_PERSON_GUID", personGuid);
    },
    setTestLbList({ commit }, testLbList) {
      commit("SET_TEST_LB_LIST", testLbList);
    },
    setNodeGuid({ commit }, nodeGuid) {
      commit("SET_NODE_GUID", nodeGuid);
    },
    setNodeName({ commit }, nodeName) {
      commit("SET_NODE_NAME", nodeName);
    },
    setOpenLbGroupGuid({ commit }, openLbGroupGuid) {
      commit("SET_OPEN_LB_GROUP_GUID", openLbGroupGuid);
    },
    setLbGroupGuid({ commit }, lbGroupGuid) {
      commit("SET_LB_GROUP_GUID", lbGroupGuid);
    },
    setCurrentLbGuid({ commit }, currentLbGuid) {
      commit("SET_CURRENT_LB_GUID", currentLbGuid);
    },
    saveAnsList({ commit }, ansList) {
      commit("SET_SAVED_ANS_LIST", ansList);
    },
    clearAnsList({ commit }) {
      commit("SET_SAVED_ANS_LIST", []);
    },
    addToSubmittedCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.submittedCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_SUBMITTED_CASE_LIST", newCaseList);
    },
    addToSavedCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.savedCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_SAVED_CASE_LIST", newCaseList);
    },
    addToCancelledCaseList({ commit, getters }, newCaseObj) {
      let newCaseList = [...getters.cancelledCaseList];
      newCaseList.push(newCaseObj);
      commit("SET_CANCELLED_CASE_LIST", newCaseList);
    },
    setShowReportCaseList({ commit }, showReportCaseList) {
      commit("SET_SHOW_REPORT_CASE_LIST", showReportCaseList);
    },
    setTimeCost({ commit }, newTimeCost) {
      commit("SET_TIME_COST", newTimeCost);
    },
    clearTimeCost({ commit }) {
      commit("SET_TIME_COST", 0);
    },
    setIsFromExternal({ commit }, isFromExternal) {
      commit("SET_IS_FROM_EXTERNAL", isFromExternal);
    }
  },

  mutations: {
    SET_REGION_GUID(state, regionGuid) {
      state.regionGuid = regionGuid;
      sessionStorage.setItem("regionGuid", regionGuid);
    },
    SET_USER_DISP_NAME(state, userDispName) {
      state.userDispName = userDispName;
      sessionStorage.setItem("userDispName", userDispName);
    },
    SET_NODE_GUID(state, nodeGuid) {
      state.nodeGuid = nodeGuid;
      sessionStorage.setItem("nodeGuid", nodeGuid);
    },
    SET_NODE_NAME(state, nodeName) {
      state.nodeName = nodeName;
      sessionStorage.setItem("nodeName", nodeName);
    },
    SET_OPEN_LB_GROUP_GUID(state, openLbGroupGuid) {
      state.openLbGroupGuid = openLbGroupGuid;
      sessionStorage.setItem("openLbGroupGuid", openLbGroupGuid);
    },
    SET_LB_GROUP_GUID(state, lbGroupGuid) {
      state.lbGroupGuid = lbGroupGuid;
      sessionStorage.setItem("lbGroupGuid", lbGroupGuid);
    },
    SET_TEST_LB_LIST(state, testLbList) {
      state.testLbList = [...testLbList];
      sessionStorage.setItem("testLbList", JSON.stringify(testLbList));
    },
    SET_SUBMITTED_CASE_LIST(state, submittedCaseList) {
      state.submittedCaseList = [...submittedCaseList];
      sessionStorage.setItem(
        "submittedCaseList",
        JSON.stringify(submittedCaseList)
      );
    },
    SET_SAVED_CASE_LIST(state, savedCaseList) {
      state.savedCaseList = [...savedCaseList];
      sessionStorage.setItem("savedCaseList", JSON.stringify(savedCaseList));
    },
    SET_CANCELLED_CASE_LIST(state, cancelledCaseList) {
      state.cancelledCaseList = [...cancelledCaseList];
      sessionStorage.setItem(
        "cancelledCaseList",
        JSON.stringify(cancelledCaseList)
      );
    },
    SET_SHOW_REPORT_CASE_LIST(state, showReportCaseList) {
      state.showReportCaseList = [...showReportCaseList];
      sessionStorage.setItem(
        "showReportCaseList",
        JSON.stringify(showReportCaseList)
      );
    },
    SET_CURRENT_LB_GUID(state, currentLbGuid) {
      state.currentLbGuid = currentLbGuid;
      sessionStorage.setItem("currentLbGuid", currentLbGuid);
    },
    SET_SAVED_ANS_LIST(state, ansList) {
      state.savedAnsList = [...ansList];
      sessionStorage.setItem("savedAnsList", JSON.stringify(ansList));
    },
    SET_PERSON_GUID(state, personGuid) {
      state.personGuid = personGuid;
      sessionStorage.setItem("personGuid", personGuid);
    },
    SET_TIME_COST(state, timeCost) {
      state.timeCost = timeCost;
      sessionStorage.setItem("timeCost", timeCost);
    },
    SET_IS_FROM_EXTERNAL(state, isFromExternal) {
      state.isFromExternal = isFromExternal;
      sessionStorage.setItem("isFromExternal", isFromExternal);
    }
  }
};
